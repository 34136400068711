<template>
  <v-container
    class="d-flex justify-center align-center"
    style="height: calc(100vh - 60px)"
    fluid
  >
    <v-card v-if="examination" width="640px">
      <v-card-title class="font-weight-bold d-flex justify-center">
        THÔNG TIN KÍP THI
      </v-card-title>

      <v-card-text class="text-center">
        <!-- Name -->
        <h4>{{ examination.name }}</h4>

        <!-- Server Time -->
        <div
          class="my-6"
          style="font-size: 28px; font-weight: 500; color: #CF1627"
        >
          {{ serverTime | datetime }}
        </div>
        <div style="cursor:pointer" @click="clipboard(linkShare)">
          Link thi: <b>{{ linkShare }}</b>
        </div>
        <div style="cursor:pointer" @click="clipboard(linkSupervisor)">
          Link giám thị: <b>{{ linkSupervisor }}</b>
        </div>
        <!-- Status -->
        <v-stepper class="my-2" flat :value="examinationStatus.value">
          <v-stepper-header>
            <v-stepper-step
              step="U"
              :complete="examinationStatus.value !== 'U'"
            >
              {{ "U" | examinationStatusText }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="P"
              :complete="
                examinationStatus.value !== 'U' &&
                  examinationStatus.value !== 'P'
              "
            >
              {{ "P" | examinationStatusText }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="S"
              :complete="examinationStatus.value === 'F'"
            >
              {{ "S" | examinationStatusText }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="F"
              :complete="examinationStatus.value === 'F'"
            >
              {{ "F" | examinationStatusText }}
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>

        <!-- Date -->
        <table
          class="px-6 py-6"
          style="width: 100%; border-collapse:separate; border-spacing: 8px;"
        >
          <tr style="padding-bottom: 8px">
            <td style="text-align: start; width: 240px; font-weight: 500">
              Thời gian vào phòng thi
            </td>
            <td style="text-align: start; color: #CF1627">
              {{ examination.startTime | time }}
            </td>
          </tr>
          <tr style="padding-bottom: 8px">
            <td style="text-align: start; width: 240px; font-weight: 500">
              Thời gian bắt đầu làm bài
            </td>
            <td style="text-align: start; color: #CF1627">
              {{ examination.startExamTime | time }}
            </td>
          </tr>
          <tr style="padding-bottom: 8px">
            <td style="text-align: start; width: 240px; font-weight: 500">
              Tổng thời gian
            </td>
            <td style="text-align: start; color: #CF1627">
              {{ examination | totalTime }}
            </td>
          </tr>
        </table>

        <v-btn
          v-if="examinationStatus.value === 'U'"
          key="U"
          block
          color="primary"
          @click="startExam"
          >BẮT ĐẦU VÀO PHÒNG THI</v-btn
        >

        <template v-else-if="examinationStatus.value === 'P'">
          <v-btn
            key="S-Monitoring"
            block
            color="secondary"
            @click="goToExamMonitoring"
          >
            GIÁM SÁT KÍP THI
          </v-btn>

          <v-btn key="P" class="mt-2" block color="primary" @click="doExam">
            BẮT ĐẦU LÀM BÀI
          </v-btn>
        </template>

        <template v-else-if="examinationStatus.value === 'S'">
          <v-btn
            key="S-Monitoring"
            block
            color="secondary"
            @click="goToExamMonitoring"
            >GIÁM SÁT KÍP THI</v-btn
          >
          <v-btn key="S" class="mt-2" block color="primary" @click="stopExam"
            >KẾT THÚC LÀM BÀI</v-btn
          >
        </template>

        <v-btn
          v-else-if="examinationStatus.value === 'F'"
          key="F"
          block
          color="success"
          @click="goToResult"
          >KẾT QUẢ KÍP THI</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import request from "@/utils/request";
import FetchServerTimeMixin from "./FetchServerTimeMixin";
import { getExaminationStatus, getStatusText } from "./constants";

export default {
  mixins: [FetchServerTimeMixin],

  filters: {
    examinationStatusText(val) {
      return getStatusText(val);
    },

    totalTime(examination) {
      if (!examination.endTime || !examination.startExamTime) return;

      return (
        dayjs(examination.endTime).diff(examination.startExamTime, "minutes") +
        " phút"
      );
    }
  },

  data() {
    return {
      examination: undefined
    };
  },

  computed: {
    examinationStatus() {
      if (!this.examination) return { text: "", value: null };

      return getExaminationStatus(this.examination, this.serverTime);
    },
    linkShare() {
      if (this.examination) {
        return (
          process.env.VUE_APP_UI_URL + "?share=" + this.examination.shareId
        );
      } else {
        return "";
      }
    },
    linkSupervisor() {
      if (this.examination) {
        return (
          process.env.VUE_APP_UI_LECTURER_URL +
          "/giamthi?share=" +
          this.examination.shareId
        );
      } else {
        return "";
      }
    }
  },

  created() {
    this.init();
  },

  methods: {
    clipboard(str) {
      const el = document.createElement("textarea");
      el.addEventListener("focusin", e => e.stopPropagation());
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$snackbar("Đã copy link thành công", "success");
    },
    init() {
      this.fetchExamination();
    },

    async startExam() {
      try {
        const confirm = await this.$confirm("Bạn có muốn bắt đầu kíp thi?", {
          color: "info",
          icon: "mdi-information",
          title: "Bắt đầu kíp thi",
          buttonTrueText: "Bắt đầu",
          buttonFalseText: "Hủy"
        });

        if (!confirm) return;

        const res = await request({
          method: "POST",
          url: "/examinations/" + this.examination.id + "/start"
        });

        await this.fetchServerTime();

        this.examination = res.data;

        this.$snackbar("Bắt đầu kíp thi!", "success");
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      }
    },

    async doExam() {
      try {
        const confirm = await this.$confirm(
          "Bạn có muốn thiết lập giờ bắt đầu làm bài?",
          {
            color: "info",
            icon: "mdi-information",
            title: "Giờ làm bài",
            buttonTrueText: "Đồng ý",
            buttonFalseText: "Hủy"
          }
        );

        if (!confirm) return;

        const res = await request({
          method: "POST",
          url: "/examinations/" + this.examination.id + "/do"
        });

        await this.fetchServerTime();

        this.examination = res.data;

        this.$snackbar("Bắt đầu giờ làm bài!", "success");
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      }
    },

    async stopExam() {
      try {
        const confirm = await this.$confirm("Bạn có muốn kết thúc kíp thi?", {
          color: "info",
          icon: "mdi-information",
          title: "Kết thúc Kíp thi",
          buttonTrueText: "Kết thúc",
          buttonFalseText: "Hủy"
        });

        if (!confirm) return;

        const res = await request({
          method: "POST",
          url: "/examinations/" + this.examination.id + "/stop"
        });

        await this.fetchServerTime();

        this.examination = res.data;

        this.$snackbar("Kết thúc Kíp thi!", "success");
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      }
    },

    cancel() {
      this.$router.back();
    },

    async fetchExamination() {
      try {
        if (!this.$route.params.id) return;

        const res = await request({
          url: "/examinations/" + this.$route.params.id
        });

        this.examination = res.data;
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      }
    },

    goToExamMonitoring() {
      this.$router.push({
        name: "Giám sát thi",
        query: { id: this.examination.id }
      });
    },

    goToResult() {
      this.$router.push({
        name: "Kết quả thi",
        params: { id: this.examination.id }
      });
    }
  }
};
</script>
